body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
  min-height: 280px;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.ant-layout-content {
  background-color: #fff;
}

.logo {
  width: 120px;
  height: 31px;
  float: left;
  margin-right: 30px;
}

div.DraftEditor-root {
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  max-height: 500px;
  overflow-y: scroll;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-top: 0;
  border-radius: 0 0 2px 2px;
}

.toolbar {
  padding: 10px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border: 1px solid #d9d9d9;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px 2px 0px 0px;
  background-color: #fafafa;
}

.ant-layout-header {
  padding: 0 !important;
}

.login-form {
  display: flex;
  min-width: 20%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: white;
  padding: 10px !important;
}

.login-bg {
  background-color: #fafafa;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.login-fields {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
